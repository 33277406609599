import { Component, Prop, Ref } from 'vue-property-decorator'
import BadgesMixin from '@/modules/level-two/views/event/modules/badges/mixins/badges.mixin'

@Component({ name: 'GtrBadgePreview' })
export default class GtrBadgePreview extends BadgesMixin {
  @Prop({ required: true, type: String, default: '' })
  badge: string|undefined

  @Prop({ required: true })
  url: string|undefined

  @Prop({ required: true, type: Boolean, default: true })
  loading: boolean|undefined

  @Prop({ required: false, type: Boolean, default: false })
  toggled!: boolean

  @Ref()
  readonly svgPreview!: InstanceType<typeof HTMLDivElement>

  get pngWidth (): any {
    if (this.svgPreview) {
      const svg = this.svgPreview.firstChild
      const width = (svg as SVGElement).getAttribute('width')
      return parseInt(width ?? '384')
    }
    return 384
  }
}
